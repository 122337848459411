
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import useTracking from 'hooks/useTracking';
import { useEffect, useState } from 'react';
import { getHomePage as apiGetHomepage } from 'services/homepage';
import MetaTagsHandler from 'components/common/MetaTagsHandler';
import { getRealLocale } from 'utils/helpers';
import FadeIn from 'components/transitions/FadeIn';
import RenderSections from 'components/common/RenderSections';
import InstagramContent from 'components/common/InstagramContent';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';

 async function _getStaticProps(context) {
  const { params, locale } = context;
  let correctLocale = getRealLocale(locale);
  const { data: pageData } = await apiGetHomepage(correctLocale);

  if (!pageData) {
    return {
      revalidate: 5,
      notFound: true,
    };
  }

  return {
    revalidate: 60,
    props: {
      pageData: { ...pageData },
    }, // will be passed to the page component as props
  };
}

export default function Home(props) {
  const { pageData } = props;
  const router = useRouter();
  const [data, setData] = useState(pageData);
  const { dispatchAllEventsByPageType } = useTracking();

  useEffect(() => {
    dispatchAllEventsByPageType('Homepage', props);
  }, [router.locale]);

  useEffect(async () => {
    const { data: pData } = await apiGetHomepage(router.locale);
    setData({ ...pData });
  }, [router.locale]);

  return (
    <FadeIn>
      {data?.metaTags && <MetaTagsHandler metaTags={data?.metaTags} />}

      <Box as="h1" display="none">
        HOMEPAGE
      </Box>
      <RenderSections sections={data?.sections} />
      <InstagramContent />
    </FadeIn>
  );
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  